.gallary-main-container {
    margin-top: 70px;
}

.gallery-title{
    text-align: center;
    font-size: 2rem;
    margin: 1rem;
}

.gallery-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin: 1rem;
    padding: 1rem;
}

.gallery-container .gallery-item{
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    cursor: pointer;
}

.gallery-container .gallery-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 400ms ease-in-out;
}

.gallery-container .gallery-item:hover img{
    transform: scale(1.1);
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .fullscreen-overlay img {
    max-width: 90%;
    max-height: 90%;
  }
  
  .close-icon {
    position: absolute;
    top: 1rem;
    right: 2rem;
    color: white;
    font-size: 2.5rem;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .gallery-title{
        font-size: 1.5rem;
    }
    .gallery-container{
        grid-template-columns: repeat(1, 1fr);
    }

    .gallery-container .gallery-item{
        margin-bottom: 1rem;
    }

    .gallery-container .gallery-item img{
        transition: none;
    }

    .fullscreen-overlay img {
        max-width: 100%;
        max-height: 100%;
    }

    .close-icon {
        top: 0.5rem;
        right: 0.5rem;
        font-size: 1.5rem;
    }

    .fullscreen-overlay {
        background-color: rgba(0, 0, 0, 0.8);
    }

    .gallery-container .gallery-item:hover img{
        transform: none;
    }

  }

@media screen and (max-width: 480px) {
    .gallery-title{
        font-size: 1.2rem;
    }

    .gallery-container{
        margin: 1rem;
        padding: 0.2rem 1rem 1rem 1rem;
    }
}