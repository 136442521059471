.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F4F5F9;
    padding-top: 40px;
    padding-inline: 126px;
}

.about-container .title {
    color: #161722;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}

.about-container .sub-title {
    color: var(--blue-letter, #161722);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.about-container .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    text-align: center;
}


@media screen and (max-width: 798px) {
    .about-container {
        padding-inline: 20px;
    }

    .about-container .title {
        font-size: 32px;
    }

    .about-container .sub-title {
        font-size: 16px;
    }

    .about-container .card-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 480px) {
    .about-container {
        padding-top: 20px;
    }

    .about-container .title {
        font-size: 32px;
        line-height: 1.2;
    }
}