.latest-blog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 587px;
    background-color: #FFF5EB;
    padding: 77px 30px 77px 107px;
    gap: 46px;
}

.latest-blog-container .image img {
    width: 644px;
    height: 432.336px;
    border-radius: 10px;
}

.latest-blog-container .content {
    display: flex;
    flex-direction: column;
}

.latest-blog-container .content .date {
    color: var(--black, #161722);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 7px;
}

.latest-blog-container .content .title {
    color: var(--black, #161722);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.32px;
}

.latest-blog-container .content .description {
    color: var(--black, #161722);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
}

.latest-blog-container .content .read-more-btn {
    display: flex;
    width: 234.773px;
    padding: 24px 64px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--primary, #FFD2A4);
    color: var(--black, #1C1D28);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 32px;
    cursor: pointer;
}

.latest-blog-container .content .read-more-btn:hover {
    background: var(--primary, #ffbf7f);
    color: var(--black, #1C1D28);
}

@media screen and (max-width: 798px){
    .latest-blog-container{
        flex-direction: column;
        height: auto;
        padding: 40px 20px;
    }

    .latest-blog-container .image img{
        width: 100%;
        height: auto;
    }

    .latest-blog-container .content{
        margin-top: 40px;
    }

    .latest-blog-container .content .title{
        font-size: 24px;
    }

    .latest-blog-container .content .description{
        font-size: 16px;
    }

    .latest-blog-container .content .read-more-btn{
        width: 50%;
        padding: 16px 32px;
        border-radius: 8px;
        justify-content: center;

        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 480px) {
    .latest-blog-container .content{
        margin-top: -30px;
        text-align: center;
    }

    .latest-blog-container .content .date {
        font-size: 12px;
        margin-bottom: 6px;
    }

    .latest-blog-container .content .title {
        line-height: 1.2;
        letter-spacing: -0.32px;
    }

    .latest-blog-container .content .description {
        font-size: 16px;
        margin-top: 10px;
    }

    .latest-blog-container .content .read-more-btn{
        justify-content: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}