/* .blog-header-container{
    margin-top: 70px;
}

.blog-header-container img{
    width: 100%;
    height: 473px;
}

@media screen and (max-width: 798px){
    .blog-header-container img{
        width: 100%;
        height: 100%;
    }
} */



 .blog-header-container {
     position: relative;
     height: 473px;
     margin-top: 70px;
 }

 .blog-header-container img {
     width: 100%;
     height: 100%;
     object-fit: cover;
 }

 .blog-header-container::before {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
     /* Dark gradient overlay */
 }

 .blog-header-container .blog-header-text {
    width: 100%;
     position: absolute;
     top: 35%;
     /* left: 5%; */
     margin-left: 85px;
     /* transform: translate(-50%, -50%); */
     display: flex;
     flex-direction: column;
     align-items: start;
 }

 .blog-header-container .blog-header-text h1 {
     color: var(--letters-main, #F5F2F0);
     text-align: center;
     font-family: Poppins;
     font-size: 64px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
 }

 .blog-header-container .blog-header-text p {
     color: var(--letters-main, #F5F2F0);
     font-family: Poppins;
     font-size: 32px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
 }

 @media screen and (max-width: 780px) {
     .blog-header-container {
         height: 100%;
     }

     .blog-header-container .blog-header-text {
         position: absolute;
         top: 40%;
         left: 25%;
         transform: translate(-50%, -50%);
         display: flex;
         flex-direction: column;
         /* align-items: center; */
         width: max-content;
     }

     .blog-header-container .blog-header-text h1 {
         font-size: 48px;
         /* margin-left: -35px; */
     }

     .blog-header-container .blog-header-text p {
         font-size: 24px;
         /* width: 600px; */
     }
 }

 @media screen and (max-width: 480px) {
     .blog-header-container .blog-header-text {
         top: 45%;
         left: 42%;
         width: auto;
         margin-left: 0px;
     }

     .blog-header-container .blog-header-text h1 {
         font-size: 35px;
     }

     .blog-header-container .blog-header-text p {
         font-size: 16px;
         /* margin-right: 20px !important; */
     }
 }