/* GoToTop.css */
.top-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.top-btn {
    font-size: 1.4rem;
    width: 3.2rem;
    height: 3.2rem;
    color: #fff;
    border-radius: 50%;
    position: fixed;
    bottom: 4rem;
    right: 1.5rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.top-btn--icon {
    animation: gototop 1.2s linear infinite alternate-reverse;
}

@keyframes gototop {
    0% {
        transform: translateY(-0.5rem);
    }

    100% {
        transform: translateY(0.5rem);
    }
}

/* @media screen and (max-width: 768px) {
    .top-btn {
        right: 0;
        left: 40%;
    }
} */

/* @media screen and (max-width: 480px) {
    .top-btn {
        right: 0;
        left: 90%;
    }
} */