* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
  }
  
  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    color: #9B968B;
    font-size: 14px;
    font-weight: 700;
  }

  a:hover{
    color: #FFFFFF;
  }

  .navbar-container{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9005;
  }
  
  /* navbar */
  .navbar-container .navbar {
    background-color: #0D0D0D;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    padding: 0 120px;
  }
  
  
  /* logo  */
  .navbar-container .navbar .logo {
    font-size: 40px;
    font-weight: 700;
    color: #FFFFFF;
    /* padding-left: 120px; */
  }
  
  
  /* ul  */
  .navbar-container .navbar .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: auto;
  }

  .navbar-container .navbar .nav-menu .active {
    color: #ffffff; 
  }

  .navbar-container .navbar .follow-us-btn{
    display: flex;
    padding: 9px 17px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: var(--secondary-color, #FFD2A4);
  }

  .navbar-container .navbar .follow-us-btn:hover{
    background-color: var(--hover-color, #ffbe81);
  }

  .navbar-container .navbar .follow-us-btn a{
    color: #000000;
  }
  
  /* media queries */
  @media screen and (max-width: 780px){
    
    /* hamburger */
    .navbar-container .navbar .hamburger {
      display: block;
    }

    .navbar-container .navbar .follow-us-btn{
      display: none;
    }

    .navbar-container .navbar{
      justify-content: space-between;
      padding: 0 20px;
    }
  
    .navbar-container .navbar .hamburger .bar {
          background-color: #FFFFFF;
          width: 20px;
          height: 3px;
          display: block;
          margin: 5px;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
      }
  
    /* adding active class to the bars  */
    .navbar-container .navbar .hamburger.active .bar:nth-child(2){
      opacity: 0;
    }
    .navbar-container .navbar .hamburger.active .bar:nth-child(1){
      transform: translateY(8px) rotate(45deg);
    }
    .navbar-container .navbar .hamburger.active .bar:nth-child(3){
      transform: translateY(-8px) rotate(-45deg);
    }
  
  
    /* navMenu */
  
    .navbar-container .navbar .nav-menu{
      position: absolute;
      flex-direction: column;
      gap: 0;
      top: 70px;
      left: -100%;
      text-align: start;
      width: 100%;
      transition: 0.7s ease-in-out;
      background-color: #0D0D0D;
      padding: 10px;
      z-index: 100;
    }
  
    .navbar-container .navbar .nav-menu.active{
      left: 0;
    }
  
    .navbar-container .navbar .nav-menu li {
      margin: 16px 0;
    }

    a {
    font-size: 16px;
    font-weight: 700;
  }
  }

  @media screen and (max-width: 834px){
    .navbar-container .navbar{
      padding: 0 20px;
    }

    .navbar-container .navbar .logo{
      font-size: 30px;
    }

    .navbar-container .navbar .nav-menu{
      gap: 10px;
    }
  }