.locationHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    padding: 2rem 0;

    font-size: 48px;
    font-weight: 700;
    color: #000;
    text-align: center;
}

@media screen and (max-width: 420px) {
    .locationHeading {
        font-size: 32px !important;
        font-weight: 700 !important;
        padding: 1rem 0;
    }
}