.sermons-card-container {
    height: 723px;
    background: var(--page-bg, #FFF5EB);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 110px 56px 110px;
}

.sermons-card-container .card-title {
    color: var(--blue-letter, #161722);
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 43px;
}

.sermons-card-container .card-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.sermons-card-container .card-content .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 435px;
    height: 512px;
    padding-left: 10px;
    padding-right: 60px;
}

.sermons-card-container .card-content .text .heading {
    color: var(--black, #161722);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    text-transform: uppercase;
}

.sermons-card-container .card-content .text .description {
    color: var(--black, #161722);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.sermons-card-container .card-content .text .time-box{
    display: flex;
    flex-direction: row;

    width: 100%;
}

.sermons-card-container .card-content .text .time-box .icon{
    width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-top: 10px;
}

.sermons-card-container .card-content .text .time-box .time {
    color: var(--black, #161722);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
    /* width: 184px; */
    width: 100%;
}

.sermons-card-container .card-content .text .join-button button {
    display: inline-flex;
    padding: 24px 64px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--black, #161722);
    color: var(--primary, #FFD2A4);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 24px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.sermons-card-container .card-content .text .join-button button:hover {
    background: var(--primary, #FFD2A4);
    color: var(--black, #161722);
}

.sermons-card-container .card-content .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 845px;
    height: 512px;
}

.sermons-card-container .card-content .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media screen and (max-width: 780px) {
    .sermons-card-container .card-title {
        font-size: 42px;
        margin-bottom: 20px;
    }

    .sermons-card-container {
        padding: 30px 20px 0px 20px;
        height: auto;
    }

    .sermons-card-container .card-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .sermons-card-container .card-content .text{
        width: 100%;
        height: auto;
        padding: 0;
        margin-bottom: 40px;
        align-items: center;
    }
    .sermons-card-container .card-content .image {
        width: 100%;
        height: auto;
    }

    .sermons-card-container .card-content .text .heading {
        font-size: 22px;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .sermons-card-container .card-content .text .description {
        font-size: 14px;
        text-align: center;
    }

    .sermons-card-container .card-content .text .time-box {
        /* width: 100%; */
        align-items: center;
        gap: 8px;
        /* vertical-align: middle; */
        /* display: flex; */
        justify-content: center;
        margin-left: 50px;
    }

    .sermons-card-container .card-content .text .time-box .icon {
        margin-right: 0;
        margin-bottom: 6px;
    }

    .sermons-card-container .card-content .text .time-box .time {
        width: 100%;
    }

    .sermons-card-container .card-content .text .join-button{
        display: flex;
        justify-content: center;
    }

    .sermons-card-container .card-content .text .join-button button {
        padding: 16px 40px;
        font-size: 18px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 480px) {
    .sermons-card-container .card-title {
        font-size: 32px;
        margin-bottom: 20px;
    }

    .sermons-card-container .card-content .text .time-box {
        margin-left: auto;
    }
}