.mission-container {
  position: relative;
  height: 689px;
}

.mission-container .img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}


.mission-container .content-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(89deg, rgba(0, 0, 0, 0) 1.96%, #252525 55%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* padding-top: 20px; */
  padding-left: 660px;
  justify-content: center;
  overflow: hidden;
}

.mission-container .content-container .title {
  color: var(--letters-main, #f5f2f0);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.mission-container .content-container .description {
  color: var(--letters-main, #f5f2f0);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
  padding-right: 60px;
}

@media screen and (max-width: 768px) {
    .mission-container {
        height: 600px;
    }
    .mission-container .img-container{
        display: none;
    }
    .mission-container .content-container {
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
        align-items: center;
        justify-content: center;
        background: #252525;
    }
    .mission-container .content-container .title {
        text-align: center;
        margin-bottom: 20px;
    }
    .mission-container .content-container .description {
        text-align: center;
        padding-right: 0;
    }
}

@media screen and (max-width: 480px) {
  .mission-container {
    height: 500px;
  }

  .mission-container .content-container {
    padding-top: 0px;
  }

  .mission-container .content-container .title {
    font-size: 32px;
    margin-bottom: 5px;
  }

  .mission-container .content-container .description {
    font-size: 16px;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .mission-container .content-container .sub {
    margin-bottom: 0px;
  }
}