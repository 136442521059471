/* *,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
} */

:root {
    --primary: #ffbe81;
    /* --white: #ffffff; */
    --white: #d0cece;
    --bg: #FFF5EB;
}

/* html {
    scroll-behavior: smooth;
}

@media (min-width: 1440px) {
    html {
        zoom: 1.5;
    }
}

@media (min-width: 2560px) {
    html {
        zoom: 1.7;
    }
}

@media (min-width: 3860px) {
    html {
        zoom: 2.5;
    }
} */

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #797979;
    transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background: #222224;
}

::-webkit-scrollbar-track {
    background: #f9f9f9;
}

/* body {
    font-size: 1.6rem;
    background: var(--bg);
} */

.container {
    max-width: 130rem;
    /* width: 85%; */
    /* padding: 4rem 1rem; */
    padding: 3rem 1rem 1rem 1rem;
    margin: 0 auto;
    height: 100%;
    /* font-size: 62.5% !important; */
}

.heading {
    color: #161722;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}

.heading-sub-title {
    color: var(--blue-letter, #161722);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
    margin-left: 85px;
    margin-right: 85px;
}

.swiper_container {
    /* height: 52rem; */
    height: 40rem;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    /* width: 37rem !important; */
    width: 28rem !important;
    /* height: 42rem; */
    height: 35rem;
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        height: 41rem;
        
    }

    .swiper-slide {
        width: 28rem !important;
        height: 36rem !important;
    }

    .swiper-slide img {
        width: 28rem !important;
        height: 36rem !important;
        border-radius: 1rem !important;
    }
}

.swiper-slide img {
    /* width: 37rem; */
    width: 28rem;
    /* height: 42rem; */
    height: 35rem;
    border-radius: 2rem;
    object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 1.7rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: 10rem !important;
    bottom: 2rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}



.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -9px) !important;
    left: auto !important;
}


@media screen and (max-width: 480px) {
    .heading {
        font-size: 32px;
        margin-top: -20px;
    }

    .heading-sub-title {
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .container{
        padding: 1.5rem 1rem 1rem 1rem;
    }
}


.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    cursor: pointer;
}

.full-screen-img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}