.sermons-header-container {
    position: relative;
    height: 473px;
    margin-top: 70px;
}

.sermons-header-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sermons-header-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    /* Dark gradient overlay */
}

.sermons-header-container .sermons-header-text {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: start;
}

.sermons-header-container .sermons-header-text h1 {
    color: var(--letters-main, #F5F2F0);
    text-align: center;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sermons-header-container .sermons-header-text p {
    color: var(--letters-main, #F5F2F0);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width: 780px) {
    .sermons-header-container {
        height: 100%;
    }

    .sermons-header-container .sermons-header-text {
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .sermons-header-container .sermons-header-text h1 {
        font-size: 48px;
        /* margin-left: -35px; */
    }

    .sermons-header-container .sermons-header-text p {
        font-size: 24px;
    }
}
    
@media screen and (max-width: 480px) {
    .sermons-header-container .sermons-header-text {
        top: 50%;
        left: 40%;
    }

    .sermons-header-container .sermons-header-text h1 {
        font-size: 35px;
    }

    .sermons-header-container .sermons-header-text p {
        font-size: 16px;
    }
}
