.welcome-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 689px;
    background: var(--page-bg, #FFF5EB);
}

.welcome-container .img-container {
    grid-column: 1 / 2;
}

.welcome-container .img-container img {
    width: 532px;
    height: 499px;
    margin: 70px 20px 70px 85px;
    border-radius: 15px;
}

.welcome-container .text-container {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    /* padding-top: 130px; */
    align-items: start;
    justify-content: center;
}

.welcome-container .text-container .title{
    color: var(--blue-letter, #161722);
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}

.welcome-container .text-container .description {
    color: var(--blue-letter, #161722);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-top: 17px;
    padding-right: 85px;
}

.welcome-container .text-container .sub-title{
    color: var(--blue-letter, #161722);
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    margin-top: 20px;
    padding-right: 85px;
}

@media screen and (max-width: 768px) {
    .welcome-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .welcome-container .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .welcome-container .img-container img {
        width: 80%;
        height: 80%;
        margin: 0;
        margin-top: 10px;
    }

    .welcome-container .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
    }

    .welcome-container .text-container .title{
        text-align: center;
        padding: 0 20px;
    }

    .welcome-container .text-container .description {
        text-align: center;
        padding: 0 20px;
        font-size: 20px;
        margin-top: 0px;
    }

    .welcome-container .text-container .sub-title{
        text-align: center;
        padding: 0 20px;
        line-height: 1.2;
        margin-bottom: 20px;
        margin-top: 20px;
        /* font-size: 28px; */
    }
}

@media screen and (max-width: 480px) {
    .welcome-container .img-container {
        margin-top: 30px;
    }

    .welcome-container .img-container img {
        width: 90% !important;
    }

    .welcome-container .text-container .sub-title{
    font-size: 23px;
    margin-top: 20px;
    line-height: 1.2;
    }

    .welcome-container .text-container .description {
    font-size: 16px;
    margin-top: 5px;
    }

    .welcome-container .text-container .title{
    font-size: 32px;
    }
}