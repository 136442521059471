@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

:root {
    --font-primary: Poppins;
  
    --primary-color: #252525;
    --secondary-color: #FFD2A4;
    --hover-color: #ffbe81;
    --black-color: #0D0D0D;
    --gray-color: #FFF5EB;
    --white-color: #ffffff;
  }