.vision-sub-container{
    background: var(--page-bg, #FFF5EB);
    height: 324px;
}

.vision-sub-container .content-container{
    padding: 40px 85px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
}

.vision-sub-container .content-container .content{
    color: var(--blue-letter, #161722);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 768px) {
    .vision-sub-container{
        height: 100%;
    }

    .vision-sub-container .content-container{
        padding: 54px 20px;
    }

    .vision-sub-container .content-container .content{
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .vision-sub-container .content-container {
        padding: 20px 20px;
        gap: 20px;
    }

    .vision-sub-container .content-container .content{
        font-size: 16px;
    }
}