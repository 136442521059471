.hero-container{
    overflow: hidden;
    background: url(../../assets/images/Hero.png) center center/cover no-repeat;
    height: 759px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    color: var(--white-color, #F5F2F0);
    /* font-size: 100% !important; */
    margin-top: 70px;
}

.hero-container .hero-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hero-container .hero-content .hero-title{
    font-family: Poppins;
    font-size: 3rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 0px;
    padding-bottom: 60px;
}

.hero-container .hero-content .hero-subtitle{
    text-align: center;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 350px 60px 350px;
}

.hero-container .hero-content .hero-description{
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 85px;
}

.hero-container .hero-content .social-icons{
    padding-top: 124px;
}

.hero-container .hero-content .down-button{
    padding-top: 28px;
    color: #FFD2A4;
}

@media  screen and (max-width: 768px) {
    .hero-container{
        height: 100% !important;
    }

    .hero-container .hero-content .hero-title{
        font-size: 1.5rem !important;
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .hero-container .hero-content .hero-subtitle{
        font-size: 48px;
        padding: 0 40px;
    }

    .hero-container .hero-content .hero-description{
        font-size: 18px;
        padding: 30px 40px 40px 40px;
    }

    .hero-container .hero-content .social-icons{
        padding-top: 40px;
    }

    .hero-container .hero-content .down-button{
        padding-top: 28px;
    }
    
}

@media screen and (max-width: 480px){
    .hero-container .hero-content .hero-title{
        font-size: 2.5rem !important;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .hero-container .hero-content .hero-subtitle{
        font-size: 40px;
        padding: 0px 40px 30px 40px;
    }

    .hero-container .hero-content .hero-description{
        font-size: 16px !important;
        padding: 0px 40px 30px 40px;
    }
}

@media screen and (max-width: 420px) {
    .hero-container .hero-content .hero-title {
        font-size: 1.5rem !important;
    }

    .hero-container .hero-content .hero-subtitle {
        font-size: 35px;
        padding: 0px 40px 30px 40px;
    }

    .hero-container .hero-content .hero-description {
        font-size: 16px !important;
        padding: 0px 30px 30px 30px;
    }
}