.vision-container{
    position: relative;
    height: 689px;
}

.vision-container .img-container{
    position: absolute;
    right: 0;
    z-index: -1;
}


.vision-container .vision-content{
    width: 100%;
    height: 100%;
    z-index: 100;
    background: linear-gradient(89deg, #252525 45.96%, rgba(0, 0, 0, 0.00) 100%);
    display: flex;
    flex-direction: column;
    align-items: start;
    /* padding-top: 113px; */
    padding-left: 85px;
    color: var(--letters-main, #F5F2F0);
    font-family: Poppins;
    justify-content: center;
}

.vision-container .vision-content .title{
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
}

.vision-container .vision-content .description{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-top: 24px;
    width: 50%;
}

@media screen and (max-width: 768px) {
    .vision-container{
        height: 100%;
    }

    .vision-container .img-container{
        display: none;
    }

    .vision-container .vision-content{
        width: 100%;
        height: 100%;
        z-index: 100;
        background: linear-gradient(89deg, #252525 100%, rgba(0, 0, 0, 0.00) 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 20px;
        padding-left: 0px;
        color: var(--letters-main, #F5F2F0);
        font-family: Poppins;
    }

    .vision-container .vision-content .title{
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
    }

    .vision-container .vision-content .description{
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        margin-top: 24px;
        width: 80%;
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .vision-container .vision-content .title{
    font-size: 32px;
    }

    .vision-container .vision-content .description{
    font-size: 16px;
    margin-top: 5px;
    width: 100%;
    padding: 0 20px;
    }

    .vision-container .vision-content .sub{
    margin-top: 15px;
    }

    .vision-container .vision-content{
    padding-top: 20px;
    }
}