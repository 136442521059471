.footer{
    background-color: #0D0D0D;
    color: #FFFFFF;
}

.footer .footer-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 46px 85px;
    margin: auto;
}

.footer .footer-container .footer-left{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin-right: 154px;
}

.footer .footer-container .footer-left .logo{
    font-size: 40px;
    font-weight: 700;
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
}

.footer .footer-container .footer-left .description{
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 31px;
}

.footer .footer-container .footer-left .address{
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 31px;
}

.footer .footer-container .footer-left .social-media .social-media-link img{
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.footer .footer-container .footer-center{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.footer .footer-container .footer-center h3{
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.footer .footer-container .footer-center .footer-links{
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 20px;
}

.footer .footer-container .footer-center .footer-links a{
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.footer .footer-container .footer-center .footer-links a:hover{
    color: #ffbe81;
}

.footer .footer-container .footer-right{
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: -100px;
}

.footer .footer-container .footer-right h3{
    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.3px;
    width: 438px;
    width: 100%;
}

.footer .footer-container .footer-right .footer-form{
    display: flex;
    width: 512px;
    height: 54px;
    flex-shrink: 0;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 12px;
    margin-top: 13px;
}

.footer .footer-container .footer-right .footer-form:focus-within{
    border: 1px solid gray;
}

.footer .footer-container .footer-right .footer-form input{
    width: 100%;
    height: 54px;
    background: transparent;
    color: var(--white, #FFF);
    text-align: left;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: none;
    padding: 20px;
}



.footer .footer-container .footer-right .footer-form input::placeholder{
    color: gray;
}

.footer .footer-container .footer-right .footer-form input:focus{
    outline: none;
}

.footer .footer-container .footer-right .footer-form button{
    display: inline-flex;
    padding: 24px 64px;
    align-items: center;
    background: #FFD2A4;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    color: var(--black, #1C1D28);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
}

.footer .footer-container .footer-right .footer-form button:hover{
    background: #ffbe81;
}

.footer .footer-container .footer-right .icon{
    font-size: 24px;
}

.footer .footer-container .footer-right span{
    margin-top: 20px;
    display: flex;
    justify-items: start;
    align-items: center;
    gap: 25px;
}

.footer .footer-container .footer-right a{
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer .footer-container .footer-right a:hover{
    color: #ffbe81;
}

.footer .bottom-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 85px;
    background: var(--bg-2, #1C1C1C);
    color: var(--body-letter, #9B968B);
}

@media screen and (max-width: 1280px){
    .footer .footer-container .footer-right{
        margin-left: 20px;
    }
}

@media screen and (max-width: 798px) {
    .footer .footer-container{
        display: block;
        padding: 20px 10px;
    }

    .footer .footer-container .footer-left{
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .footer .footer-container .footer-left .logo{
        font-size: 30px;
        text-align: center;
    }
    
    .footer .footer-container .footer-left .description{
        font-size: 20px;
        text-align: center;
    }
    
    .footer .footer-container .footer-left .address{
       font-size: 20px;
       text-align: center;
    }

    .footer .footer-container .footer-center{
        display: none;
    }

    .footer .footer-container .footer-right{
        display: none;
    }

    .footer .bottom-bar{
        justify-items: center;
        align-items: center;
        padding: 5px 100px;
        font-size: 15px;

        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        align-items: center;
    }

    .footer .bottom-bar .bottom-bar-right{
        display: none;
    }
}

@media screen and (max-width: 820px){
    .footer .footer-container{
        padding: 20px 20px;
    }

    .footer .footer-container .footer-center{
        display: none;
    }

    .footer .footer-container .footer-left{
        margin-right: 70px;
    }

    .footer .footer-container .footer-right .footer-form{
        width: 412px;
    }

    .footer .footer-container .footer-right .footer-form button{
        display: inline-flex;
        padding: 24px 40px;
    }
}

@media screen and (max-width: 480px) {
    .footer .footer-container .footer-left .logo{
        font-size: 32px;
    }

    .footer .footer-container .footer-left .description{
        font-size: 16px;
        margin-bottom: 15px;
    }

    .footer .footer-container .footer-left .address{
        font-size: 16px;
        margin-bottom: 20px;
    }

    .footer .footer-container .footer-left .social-media .social-media-link img{
        width: 40px;
        height: 40px;
    }

    .footer .bottom-bar{
        /* display: block; */
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        align-items: center;
        padding: 11px 10px;
        font-size: 14px !important;
    }

    .footer .footer-container .footer-right{
        display: block;
    }

    .footer .footer-container .footer-right h3{
        display: none;
    }

    .footer .footer-container .footer-right .footer-form{
        display: none;
    }

    .footer .footer-container .footer-right .icon{
    font-size: 20px;
    }

    .footer .footer-container .footer-right a{
        font-size: 16px !important;
    }

    .footer .footer-container .footer-right span{
        gap: 8px;
        margin-top: 10px;
    }

    .footer .footer-container .footer-right{
        margin: 10px 5px 0px 5px ;
        display: flex;
        align-items: center;
    }

}



